import { NavLink } from '@remix-run/react'
import cn from 'classnames'

import { BlogCategory } from '@/utils/types'

type Props = {
  activeClassName?: boolean
  category: BlogCategory
  className?: string
}

export default function BlogCategoryLink({ activeClassName, category, className }: Props) {
  const label: string = labels[category]

  return (
    <NavLink
      className={({ isActive }) =>
        cn(className, {
          'bg-blue-600 text-white hover:text-white dark:bg-blue-500 dark:text-black dark:hover:text-black':
            activeClassName && isActive && category === BlogCategory.Engineering,
          'text-blue hover:bg-blue-100 dark:hover:bg-blue-900':
            (!activeClassName || !isActive) && category === BlogCategory.Engineering,
          'bg-orange-500 text-white hover:text-white dark:text-black dark:hover:text-black':
            activeClassName && isActive && category === BlogCategory.Vitess,
          'text-orange hover:bg-orange-100 hover:text-orange dark:hover:bg-orange-900':
            (!activeClassName || !isActive) && category === BlogCategory.Vitess,
          'bg-green-600 text-white hover:text-white dark:bg-green-500 dark:text-black dark:hover:text-black':
            activeClassName && isActive && category === BlogCategory.Product,
          'text-green hover:bg-green-100 hover:text-green dark:hover:bg-green-900':
            (!activeClassName || !isActive) && category === BlogCategory.Product,
          'bg-purple-600 text-white hover:text-white dark:bg-purple-500 dark:text-black dark:hover:text-black':
            activeClassName && isActive && category === BlogCategory.Tutorials,
          'text-purple hover:bg-purple-100 hover:text-purple dark:hover:bg-purple-900':
            (!activeClassName || !isActive) && category === BlogCategory.Tutorials,
          'bg-red-600 text-white hover:text-white dark:bg-red-500 dark:text-black dark:hover:text-black':
            activeClassName && isActive && category === BlogCategory.Company,
          'text-red hover:bg-red-100 hover:text-red dark:hover:bg-red-900':
            (!activeClassName || !isActive) && category === BlogCategory.Company
        })
      }
      prefetch='intent'
      to={`/blog/category/${category}`}
    >
      {label}
    </NavLink>
  )
}

const labels: Record<BlogCategory, string> = {
  [BlogCategory.Company]: 'Company',
  [BlogCategory.Engineering]: 'Engineering',
  [BlogCategory.Product]: 'Product',
  [BlogCategory.Tutorials]: 'Tutorials',
  [BlogCategory.Vitess]: 'Vitess'
}
